/* ------------------------------------ *\
  #SCROLLBOX
\* ------------------------------------ */

.scrollbox {
  width: 100%;

  transform: translate3d(0, 0, 0);

  overflow-x: scroll;
  overflow-y: hidden;

  -webkit-overflow-scrolling: touch;
  will-change: max-height;
}

.scrollbox__projects {
  height: 320px;

  background-color: $yom-dusty;
}

.scrollbox__cards {
  width: 3850px;
  height: 100%;

  @include media-query(phone) {
    width: 300%;
  }

  &:after {
    overflow: auto;
    zoom: 1;
  }
}

.scrollbox__card {
  position: relative;

  float: left;
  width: 550px;
  min-width: 14.28%;
  height: 100%;

  @include media-query(phone) {
    width: 25%;
  }

  &:hover {
    .card--info {
      opacity: 0;
    }
  }
}

.card--info {
  position: absolute;
  z-index: 2;

  width: 100%;
  height: 100%;

  padding: 30px 35px;

  background-color: rgba(0, 0, 0, 0.4);

  color: $yom-white;

  font-size: 17px;
}

.scrollbox__logo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 25%;

  margin: auto;

  img {
    position: absolute;
    top: 0;
    bottom: 0;

    width: 100%;

    margin: auto;
  }
}

// SLIDEINOUT

.slideInOut {
  max-height: 320px;

  visibility: visible;
}

.slideInOut.ng-hide-add.ng-hide-add-active,
.slideInOut.ng-hide-remove.ng-hide-remove-active {
  transition: all 0.5s ease-in-out;
}

.slideInOut.ng-hide {
  max-height: 20px;

  overflow-y: hidden;
}

.slideInOut.ng-hide {
  display: block !important;

  visibility: hidden;
}
