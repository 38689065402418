/* ------------------------------------ *\
  #CARDS
\* ------------------------------------ */

$card-tl: 70%;
$card-bl: 34%;
//$card-tl + $card-bl == 104%

$card-tr: 60%;
$card-br: 44%;
//$card-tr + $card-br == 104%


/* CARD BASIC */
.card {
  position: relative;

  display: block;
  width: 100%;
  height: 100%;

  background-color: $yom-dusty;

  overflow: hidden;
}

.card__animate {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* CARD BACKGROUND */
.card__bg {
  background-size: cover;
}

.card__bg--c {
  background-position: center;
}

/* CARD OVERLAY */
.card__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100%;

  padding-top: 50px;
  padding-right: 60px;
  padding-bottom: 50px;
  padding-left: 60px;
}

.scherm-1 {
  .card--sb {
    height: $card-tl;
  }

  .card--gj {
    height: $card-tr;
  }
}

.scherm-1 {
  .card--sb,
  .card--gj,
  .card--sb-project,
  .card--gj-project {
    @include media-query(phone) {
      min-height: 325px;
    }
  }
}

.card--sb {
  background-position: center;
}

.card--sb-project {
  height: $card-bl;

  /* stylelint-disable */
  background: #eb4356;
  background: -moz-linear-gradient(90deg, #eb4356 0%, #ef7a56 100%);
  background: -webkit-linear-gradient(90deg, #eb4356 0%, #ef7a56 100%);
  background: linear-gradient(90deg, #eb4356 0%, #ef7a56 100%);
  background: linear-gradient(90deg, #eb4356, #ef7a56);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb4356', endColorstr='#ef7a56',GradientType=1 );
  /* stylelint-enable */

  background-repeat: no-repeat;
  background-position: -20% bottom;

  img {
    max-width: 75% !important;
    max-height: 75% !important;
  }

  @include media-query(phone) {
    min-height: 325px;
  }
}

.card--gj {
  background-position: center;

  @include media-query(phone) {
    background-position: 0 63%;
  }
}

.card--gj-project {
  height: $card-br;

  background-color: #f7a100;

  img {
    max-width: 85% !important;
    max-height: none !important;
  }

  @include media-query(phone) {
    min-height: 325px;
  }

  .project__name,
  .project__year {
    color: $yom-white;
  }
}

.card--sb,
.card--gj {
  @include media-query(phone) {
    height: 525px;
  }
}

/* --- PROFILE --- */
.profile__name {
  position: absolute;
  bottom: 45px;
  left: 45px;

  margin: 0;
  padding: 0 30px 0 0;

  color: $yom-white;

  font-size: 17px;

  line-height: 20px;
}

.card__overlay,
.profile__overlay {
  opacity: 0;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.93);

    opacity: 1;
  }
}

.profile__name--overlay,
.project__name--overlay {
  margin: 0;
  padding: 0;

  color: $yom-dusty;

  font-size: 17px;

  line-height: 26px;

  b {
    display: block;

    margin-bottom: 8px;

    color: $yom-cod;

    font-size: 26px;
  }
}

.profile__btns,
.project__btns {
  position: absolute;
  bottom: 50px;

  display: block;

  .btn {
    float: left;

    margin-right: 10px;
  }

  &:after {
    display: table;

    clear: both;

    content: '';
  }
}

/*  --- PROJECT --- */
.project__name {
  position: absolute;
  top: 35px;
  left: 45px;

  margin: 0;
  padding: 0;

  color: $yom-cod;

  font-size: 17px;
  font-weight: 700;
}

.project__year {
  position: absolute;
  right: -30px;
  bottom: 90px;

  width: 140px;

  margin: 0;

  transform: rotate(-90deg);

  @include font-domine();
  color: $yom-cod;

  font-size: 14px;
}

.card__project {
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    max-width: 50%;
    height: auto;
    max-height: 50%;

    margin: auto;
  }
}

.scherm-2 .card__project {
  @include media-query(portable) {
    height: 300px;
  }
}

.card--lp {
  height: 20%;

  background-color: #e7401e;

  .card__bg {
    background-color: transparent !important;
    background-position: 58.5% bottom;
    background-size: 175%;
  }

  img {
    max-width: 80%;
  }
}

.card--shell {
  height: 25%;

  background-color: #003c88;

  img {
    max-width: 80%;
  }
}

.card--allerhande {
  height: 25%;

  background-color: #6cbc88;

  .card__bg {
    background-position: center right;
  }

  img {
    max-width: 40%;
  }
}

.card--fedex {
  height: 25%;

  background-color: #4d148c;

  img {
    max-width: 60%;
  }
}

.card--sunweb {
  height: 25%;

  background-color: #ff0;

  .card__bg {
    background-position: center right;
  }
}

.card--liander {
  height: 20%;

  background-color: #811d7d;

  .card__bg {
    background-position: top right;
  }

  img { max-width: 38%; }
}

.card--tnt {
  height: 25%;

  background-color: #f60;

  img {
    max-width: 60%;
  }
}

.card--ecover {
  height: 20%;

  background-color: #85c300;

  img {
    max-width: 40%;
    max-height: 100%;
  }
}

.card--ls {
  height: 20%;

  /* stylelint-disable */
  background: #f0f0f0;
  background: -moz-linear-gradient(45deg, #f0f0f0 0%, #fff 69%);
  background: -webkit-linear-gradient(45deg, #f0f0f0 0%, #fff 69%);
  background: linear-gradient(45deg, #f0f0f0 0%, #fff 69%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f0f0', endColorstr='#ffffff',GradientType=1 );
  /* stylelint-enable */

  .overlay__name {
    @include media-query (desk) {
      margin-top: -30px;
    }

    b {
      font-size: 27px;

      @include media-query (phone) {
        font-size: 21px;
      }
    }
  }

  img { max-width: 48%; }
}

.card--be {
  height: 20%;

  background-color: #f1f1f1;

  img { max-width: 62%; }
}

.card--ing {
  height: 25%;

  background-color: #999;

  .card__bg {
    background-position: center center;
  }
}

.card--ldd {
  height: 25%;

  background-color: #faf0c4;

  .overlay__name {
    @include media-query (desk) {
      margin-top: -30px;
    }

    b {
      font-size: 27px;

      @include media-query (phone) {
        font-size: 21px;
      }
    }
  }

  img {
    max-width: 74%;
    max-height: 100%;
  }
}

.card--ultimaker {
  height: 25%;
}

.card--ldd,
.card--ultimaker {
  @include media-query(phone-to-desk) {
    float: left;
    width: 50%;
  }
}

.card__bg--tnt {
  background-color: #f60;
}
